import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyCavahaq3XLAfO_h1PW-URzbu0JJorHwNU",
    authDomain: "goody-2367b.firebaseapp.com",
    projectId: "goody-2367b",
    storageBucket: "goody-2367b.appspot.com",
    messagingSenderId: "559250367401",
    appId: "1:559250367401:web:b5d60f5e2e1d9dfbb61ab8",
    measurementId: "G-SCZGK8V4LF"
}
firebase.initializeApp(config)
export default firebase

export const database = firebase.database()
export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
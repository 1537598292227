import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from './firebase'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use( CKEditor );


Vue.config.productionTip = false
const store = new Vuex.Store({
  state: {
    drawer: true
  },
  mutations: {
    toggle (state) {
      state.drawer = !state.drawer
      console.log(state)
    }
  },
  getters: {
    getDrawer: state => {
      return state.drawer
    }
  }
})
new Vue({
  store,
  router,
  firebase,
  render: h => h(App),
}).$mount('#app')

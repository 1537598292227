import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import homepage from '../views/homepage.vue'
// blod
import blogs from '../views/blogs/index.vue'
import addBlog from '../views/blogs/new.vue'
import editblog from '../views/blogs/_id.vue'
// product
import products from '../views/products/index.vue'
import addProduct from '../views/products/new.vue'
import editProduct from '../views/products/_id.vue'
import order from '../views/order/index.vue'
import account from '../views/account/index.vue'
import login from '../views/login.vue'
// boon
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/index',
    name: 'homepage',
    component: homepage
  },
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/account',
    name: 'account',
    component: account
  },
  // blogg
  {
    path: '/blogs/:id',
    name: 'editblog',
    component: editblog
  },
  {
    path: '/newBlog',
    name: 'addblog',
    component: addBlog
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: blogs
  },
  // product
  {
    path: '/products/:id',
    name: 'editproduct',
    component: editProduct
  },
  {
    path: '/newProduct',
    name: 'addproduct',
    component: addProduct
  },
  {
    path: '/products',
    name: 'products',
    component: products
  },
  {
    path: '/',
    name: 'Dashboard',
    component: homepage
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
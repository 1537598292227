<template>
    <main style=";">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="mx-10 my-10">
        <h2 class="text-lg font-medium text-gray-900">Projects</h2>
        <p class="mt-1 text-sm text-gray-500">You haven’t created a project yet. Get started by selecting a template or start from an empty project.</p>
        <ul role="list" class="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
          <li class="flow-root">
            <div class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
              <div class="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-pink-500">
                <!-- Heroicon name: outline/view-list -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
              </div>
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  <a href="/products" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Add Product<span aria-hidden="true"> &rarr;</span>
                  </a>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Another to-do system you’ll try but eventually give up on.</p>
              </div>
            </div>
          </li>

          <li class="flow-root">
            <div class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
              <div class="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-yellow-500">
                <!-- Heroicon name: outline/calendar -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  <a href="/blogs" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Create Blog<span aria-hidden="true"> &rarr;</span>
                  </a>
                </h3>
                <p class="mt-1 text-sm text-gray-500">Stay on top of your deadlines, or don’t — it’s up to you.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </main>
</template>
<script>
export default {
    data() {
        return {
            websiteViews: 0,
            productAmount: 0,
            blogAmount: 0,
            lst : [
                {
                    bg: '#CCFFCC',
                    name: 'nft Good',
                    status: 'active',
                    earnings: '$2,500.50'
                },
                {
                    bg: '#CCFFCC',
                    name: 'axie Good',
                    status: 'deactive',
                    earnings: '$5,500.50'
                },
                {
                    bg: '#CCFFCC',
                    name: 'Crypto Car Good',
                    status: 'active',
                    earnings: '$1,500.50'
                },
                {
                    bg: '#CCFFCC',
                    name: 'Crypto Plane Good',
                    status: 'active',
                    earnings: '$500.50'
                }
            ]
        }
    },
    
}
</script>
<style >

.header:before {
    background: #fed8b1;
    content: "";
    display: inline-block;
    width: 10px;
    height: 30px;
    
}
</style>


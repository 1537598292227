<template>
    <main>
      <div class="mx-8 my-8 space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Blogs
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be displayed publicly so be careful what you share.
              </p>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="title" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Title</label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input id="title" v-model="title" type="text" name="title" autocomplete="title" class="px-4 py-2 max-w-lg block w-full shadow-sm focus-within:outline-none focus-within:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-2 border-gray-200 rounded-md">
                </div>
              </div>
              <div class=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="author" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Author</label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input id="author" v-model="author" type="text" name="author" autocomplete="author" class="px-4 py-2 max-w-lg block w-full shadow-sm focus-within:outline-none focus-within:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-2 border-gray-200 rounded-md">
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first-name" class=" sm:col-span-3 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Content</label>
                <div class="mt-1 sm:mr-8 sm:mt-0 sm:col-span-3">
                  <ckeditor style=" margin-bottom:40px;" :upload-adapter="uploader" :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
              </div>
              <div v-show="!displaySrc" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Cover photo </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div class="space-y-1 text-center">
                      <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <div class="flex text-sm text-gray-600">
                        <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                          <span>Upload a file</span>
                          <input @change="uploadThumbnail" id="file-upload" name="file-upload" type="file" class="sr-only">
                        </label>
                        <p class="pl-1">
                          or drag and drop
                        </p>
                      </div>
                      <p class="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="displaySrc" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Cover photo </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  
                  <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <img class=" sm:w-1/2 grid justify-items-center" :src="displaySrc" alt="" srcset="">
                    <div class="relative ml-6">
                      <div class="absolute top-0 right-0 ">
                        
                        <svg @click="selectedFileClose" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer h-6 w-6 hover:bg-gray-100" fill="none" viewBox="0 0 24 24" stroke="red" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                SEO Information
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Use a permanent address where you can receive mail.
              </p>
            </div>
            <div class="space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="metaTitle" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">  SEO Title</label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="metaTitle" id="metaTitle" type="text" name="metaTitle" autocomplete="metaTitle" class="px-4 py-2 max-w-lg block w-full shadow-sm focus-within:outline-none focus-within:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-2 border-gray-200 rounded-md">
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Slug</label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="slug" id="slug" type="text" name="slug" autocomplete="slug" class="px-4 py-2 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-2 border-gray-200 rounded-md">
                </div>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="metaDescription" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Meta Description </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input v-model="metaDescription" id="metaDescription" type="text" name="description" autocomplete="description" class="px-4 py-2 max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-2 border-gray-200 rounded-md">
                </div>
              </div>
            </div>
        </div>
         <div v-show="path" class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Publish
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Use a permanent address where you can receive mail.
              </p>
            </div>
            <div class="space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="metaTitle" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">  Publish</label>
                
                <span v-show="published" class="relative z-0 inline-flex shadow-sm rounded-md">
                  <button  @click="publish" type="button" class=" relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    Draft
                  </button>
                  <button type="button" class="bg-blue-600  -ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    Publish
                  </button>
                </span>
                <span v-show="!published" class="relative z-0 inline-flex shadow-sm rounded-md">
                  <button  type="button" class="bg-blue-600 relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-white  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    Draft
                  </button>
                  <button @click="publish" type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    Publish
                  </button>
                </span>
            
              </div>
            </div>
            
        </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <router-link to="/blogs">
              <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Cancel
              </button>
            </router-link>
            <button v-show="!change && !loading" type="submit" class="cursor-not-allowed ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </button>
            <button v-show="!loading && change" @click="save" type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </button>
             <button v-show="loading" type="submit" class="cursor-wait animate-pulse disabled ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              
              Processing
            </button>

          </div>
        </div>
        <!-- modal -->
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div v-show="modal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <!--
              Background overlay, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <!--
              Modal panel, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
            <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <!-- Heroicon name: outline/check -->
                  <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Save successful</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Would you like to publish this blog </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button @click="publish" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">Publish</button>
                <button @click="modal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">Later</button>
              </div>
            </div>
          </div>
        </div>
        <!-- notification -->
        <!-- Global notification live region, render this permanently at the end of the document -->
        <div v-show="notification" aria-live="assertive" class="top-2 right-0 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
          <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
            <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="p-4">
                <div class="flex items-start">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: outline/check-circle -->
                    <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-medium text-gray-900">Successfully saved!</p>
                    <p class="mt-1 text-sm text-gray-500">Your details were successfully saved.</p>
                  </div>
                  <div class="ml-4 flex-shrink-0 flex">
                    <button @click="notification = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span class="sr-only">Close</span>
                      <!-- Heroicon name: solid/x -->
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
</template>
<script>
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader
  }
  async upload () {
    const file = await this.loader.file
    console.log(file)
    const metadata = {contentType : 'image/jpeg'}
    const uploadTask = firebase.storage().ref(`cars/${Date.now()}`).put(file, metadata)
    try {
        await uploadTask.setMeta
        const url = await uploadTask.then(snapshot => snapshot.ref.getDownloadURL() )
        // eslint-disable-next-line
        return  {default: url}
    } catch (err) {
        // eslint-disable-next-line
        console.log(err)
        alert('error')
    }
  }
  abort () {
    
  }


}
  // <--- ADDED
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';
// other
import firebase from 'firebase';
export default {
    async mounted () {
      if (this.$route.params.id === 'new') { 
        return
      }
      const ref = await firebase.firestore().collection('blogs').doc(this.$route.params.id).get()
      this.payload = ref.data()
      this.editorData = this.payload['content']
      this.title = this.payload['title']
      this.id = this.payload['id']
      this.path = this.payload['path']
      this.author = this.payload['author']
      this.storagePath = this.payload['storagePath']
      this.thumbnail = this.payload['thumbnail']['original']
      this.displaySrc = this.thumbnail
      this.thumbnailAlt = this.payload['thumbnailAlt']
      this.tags = this.payload['tags'] || []
      this.metaTitle = this.payload['metaTitle']
      this.metaDescription = this.payload['metaDescription']
      this.slug = this.payload['slug']
      this.path = this.payload['path']
      this.published = this.payload['published']

    },
    data() {
      return {
        change: false,
        notification: false,
        modal: false,
        published: false,
        publishing: false,
        items: ['Gaming', 'Programming', 'Vue', 'Vuetify'],
        model: [],
        search: null,
        displaySrc: '',
        payload: {},
        editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
            image: {
                toolbar: [
                    '|',
                    'imageResize',
                    '|',
                    'imageTextAlternative'
                ],

                // The default value.
                styles: [
                    'alignLeft', 'alignCenter', 'alignRight'
                ],
            },
            plugins: [
                Alignment,
                Autoformat,
                BlockQuote,
                Bold,
                Essentials,
                Heading,
                HtmlEmbed,
                Image,
                ImageCaption,
                ImageResize,
                ImageStyle,
                ImageToolbar,
                ImageUpload,
                Indent,
                Italic,
                Link,
                LinkImage,
                MediaEmbed,
                Paragraph,
                PasteFromOffice,
                SimpleUploadAdapter,
                Table,
                TextTransformation,
                WordCount
            ],
            extraPlugins: [this.uploader],
            toolbar: {
                items: [
                    'heading',
                    'alignment',
                    'bold',
                    'italic',
                    'link',
                    'imageUpload',
                    'undo',
                    'redo'
                ]
            }
            // The configuration of the editor.
        },
        editThumbnail: false,
        loading: false,
        description: '',
        selectedFile: null,
        isSelecting: false,
        original: {},
        // payload
        author: '',
        title: '',
        id: '',
        path: '',
        storagePath: '',
        thumbnail: {
            xs: '',
            md: '',
            lg: '',
            original: ''
        },
        thumbnailAlt: '',
        tags: [],
        metaTitle: '',
        metaDescription: '',
        slug: '',

      }
    },
    watch: {
      title () {
        this.validate()
      },
      author () {
        this.validate()
      },
      editorData () {
        this.validate()
      },
      slug () {
        this.validate()
      },
      metaTitle () {
        this.validate()
      },
      metaDescription () {
        this.validate()
      },
      displaySrc () {
        this.validate()
      },

    },
    methods: {
      validate () {
        let validation = []
        if (this.$route.params.id === 'new') {
          validation = [
            this.displaySrc !== '',
            this.metaDescription !== '',
            this.editorData !== '',
            this.metaTitle !== '',
            this.slug !== '',
            this.title !== '',
            this.author !== '',
            this.gallery !== []
          ]
          if (validation.every(x => x === true)) {
            this.change = true
          } else {
            this.change = false
          }
        } else {
          const validation = [
            this.displaySrc !== this.payload['thumbnail']['original'],
            this.metaDescription !== this.payload['metaDescription'],
            this.metaTitle !== this.payload['metaTitle'],
            this.slug !== this.payload['slug'],
            this.editorData !== this.payload['content'],
            this.author !== this.payload['author'],
            this.title !== this.payload['title']
          ]
          if (validation.includes(true)) {
            this.change = true
          } else {
            this.change = false
          }
        }

      },
      modalToggle () {
        alert(this.modal)
        this.modal = false
      },
      async publish () {
        this.loading = true
        this.published = !this.published
        const ref = firebase.firestore().doc(this.path)
        await ref.update({published: this.published})
        this.loading = false
        this.modal = false
      },
      selectedFileClose () {
        this.selectedFile = null
        this.displaySrc = ''
      },
      addToList (v) {
          this.tags.push(v[0])
          console.log(this.tags)
          this.model = []
      },
      removeTag (item) {
          this.tags.splice(this.tags.indexOf(item), 1)
          this.tags = [...this.tags]
      },
      backToBlogs () {
          this.$router.push({name: 'blogs'})
      },
      async saveNew () {
        this.loading = true
        const payload = {
            published: false,
            title: this.title,
            author: this.author,
            content: this.editorData,
            thumbnailAlt: this.thumbnailAlt,
            tags: this.tags,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            slug: this.slug,
            dateModify: firebase.firestore.FieldValue.serverTimestamp(),
            dateModifyTimeZone: firebase.firestore.FieldValue.serverTimestamp(),
        }
        const newRef = firebase.firestore().collection('blogs')
        const result = await newRef.add(payload)
        const storageRef = firebase.storage().ref(`blogs/${result.id}/thumbnail`)
        await storageRef.put(this.selectedFile, this.selectedFile.type)
        const root = firebase.storage().ref('')
        var listRef = await (root.child(`/blogs/${result.id}`).listAll())
        while (listRef.items.length !== 4) { 
        await new Promise(r => setTimeout(r, 3000))
        listRef = await (root.child(`/blogs/${result.id}`).listAll())
        }
        const allLink = listRef.items.map(x => x)
        const allLinks = allLink.map( async x =>  {
            const c = await x.getDownloadURL().then(y => y)
            return c
        })
        const promise = await Promise.all(allLinks)
        console.log(promise)
        // **************
        //  lists download link of picture in storage
        // update all list of thumbnail, firestoreid, firestorepath to firestore 
        //
        const thumbnail = {
            thumbnail: {
                xs:  promise[1],
                md: promise[2],
                lg: promise[3],
                original: promise[0]
            },
        }
        const updateRef = firebase.firestore().doc(`/blogs/${result.id}`)
        await updateRef.update({path: `/blogs/${result.id}`,id: result.id, ...thumbnail })
        this.path = `/blogs/${result.id}`
        this.loading = false

      },
      async save () {
        //  new
        if (this.$route.params.id === 'new') {
          await this.saveNew()
          this.modal = true
          return
        }
        // old
        this.loading = true
        const payload = {
            publish: this.published,
            title: this.title,
            author: this.author,
            content: this.editorData,
            thumbnailAlt: this.thumbnailAlt,
            tags: this.tags,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            slug: this.slug,
            dateModify: firebase.firestore.FieldValue.serverTimestamp(),
            dateModifyTimeZone: firebase.firestore.FieldValue.serverTimestamp(),
        }
        const ref = firebase.firestore().doc(this.path)
        await ref.update(payload)
        if (this.editThumbnail) {
            const storageRef = firebase.storage().ref(`blogs/${this.id}/thumbnail`)
            await storageRef.put(this.selectedFile, this.selectedFile.type)
            
            const root = firebase.storage().ref('')
            var listRef = await (root.child(`/blogs/${this.id}`).listAll())
            while (listRef.items.length !== 4) { 
            await new Promise(r => setTimeout(r, 3000))
            listRef = await (root.child(`/blogs/${this.id}`).listAll())
            }
            const allLink = listRef.items.map(x => x)
            const allLinks = allLink.map( async x =>  {
                const c = await x.getDownloadURL().then(y => y)
                return c
            })
            const promise = await Promise.all(allLinks)
            console.log(promise)
            // **************
            //  lists download link of picture in storage
            // update all list of thumbnail, firestoreid, firestorepath to firestore 
            //
            const thumbnail = {
                thumbnail: {
                    xs:  promise[1],
                    md: promise[2],
                    lg: promise[3],
                    original: promise[0]
                },
            }
            const updateRef = firebase.firestore().doc(this.path)
            await updateRef.update({ ...thumbnail })
        }
        this.notification = true
        
        this.loading = false
          
      },
      onButtonClick () {
          this.isSelecting = true
          window.addEventListener('focus', () => {
              this.isSelecting = false
          }, { once: true })

          this.$refs.uploader.click()
      },
      async uploadThumbnail (e) {
          this.selectedFile = e.target.files[0]
          if (this.selectedFile['size'] > 2000000 ) {
              alert('file size exceed 2mb')
              this.selectedFile = null
              return
          }
          else {
              let _this = this
              const reader = new FileReader()
              
              reader.onload = function (e) {
                  console.log(e.target.result)
                  _this.editThumbnail = true
                  _this.displaySrc = e.target.result
                  
              }
              reader.readAsDataURL(_this.selectedFile)      
          }
          
      },
      uploader (editor) {
          editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new MyUploadAdapter( loader );
          } 
      }
    },
    
}
</script>
<style >
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    min-height: 300px;
    height: auto;
    
}
</style>

